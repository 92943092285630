import ssfHost from '@elliemae/em-ssf-host'
import printJS from 'print-js'
import {
  EM_EVENT, APP_SESSION_PREFIX
} from '../common/Constants'
import { Logger } from '../common/Logger'
import { resolveParent } from '../widget/ParentWindowProxy'

// The EncompassApplication provides access to the top-level Application resources
export default function HostWindow (callback = function () {}) {
  // Derive from the scripting object
  ssfHost.ScriptingObject.call(this, 'HostWindow')
  // Define the application events
  this.windowPropertyChanged = new ssfHost.Event()
  /**
   * method disable back button in parent window
   * @private
   * @param {string} html
   */
  this.documentWrite = function (html) {
    window.document.write(html)
  }

  this.scrollTo = function (x, y) {
    window.scrollTo(x, y)
  }

  this._sameOriginParent = resolveParent()

  /**
   * method disable back button in parent window
   * @private
   */
  this.disableBrowserBack = function () {
    window.history.pushState({ disableBrowserBack: true }, window.document.title)
    delete window.onpopstate
    window.onpopstate = (e) => {
      if (e.state.disableBrowserBack === true) {
        window.history.pushState({ disableBrowserBack: true }, window.document.title)
        window.scrollTo(0, 0)
      }
    }
  }

  /**
   * method disable back button in parent window
   * @private
   * @param {string} url
   */
  this.redirect = function (url) {
    window.location.href = url
  }
  /**
   * method replace state parent window
   * @private
   * @param {object} state
   * @param {string} title
   * @param {string} url
   */
  this.replaceState = function (state, title, url) {
    window.history.replaceState(state, title, url)
  }

  this.pushState = function (state, title, url) {
    window.history.pushState(state, title, url)
  }

  this.persistWidgetUrlState = function (wid, state, forceResume) {
    /* eslint-disable */
    callback({
      name: EM_EVENT.widgetStateChanged,
      wid: wid,
      message: {state: state, forceResume: forceResume}
    }) 
    /* eslint-enable */
  }

  this.setIFrameHeight = function (wid, newHeight) {
    /* eslint-disable */
    callback({
      name: EM_EVENT.widgetResized,
      wid: wid,
      message: { height: newHeight }
    }) 
    /* eslint-enable */
  }

  this.openNewWindow = function (url, name, specs, replace) {
    const popupWin = window.open(url, '_blank', specs, replace)
    if (popupWin) {
      popupWin.document.title = name
      popupWin.location.href = url
    }
  }

  this.historyGo = function (historyNumber = -1) {
    window.history.go(historyNumber)
  }

  this.setStateItem = function (key, state = {}) {
    const parentWindow = this._sameOriginParent
    try {
      if (parentWindow !== null) {
        const KEY = (APP_SESSION_PREFIX + key) // Ex. ECC_GUEST_ + IS_ACTIVE
        parentWindow.sessionStorage.setItem(KEY, state)
      }
    } catch (error) {
      Logger.debug('setStateItem: could not set data in the parent ' + error)
    }
  }

  this.removeStateItem = function (key) {
    const parentWindow = this._sameOriginParent
    if (parentWindow !== null) {
      const KEY = (APP_SESSION_PREFIX + key)
      parentWindow.sessionStorage.removeItem(KEY)
    }
  }

  this.clearState = function () {
    const parentWindow = this._sameOriginParent
    if (parentWindow !== null) {
      parentWindow.sessionStorage.clear()
    }
  }

  this.printSkyDriveDocument = (blob) => {
    const windowURL = window.URL || window.webkitURL
    const fileUrl = windowURL.createObjectURL(blob)
    setTimeout(() => {
      windowURL.revokeObjectURL(fileUrl)
    }, 10000)

    // catch popup blocker error
    try {
      printJS(fileUrl)
      return true
    } catch (e) {
      Logger.error(e.toString())
      return false
    }
  }

  this.subscribeBodyResizeObserver = function () {
    window.resizeObserver.observe(window.document.body)
  }

  this.unSubscribeBodyResizeObserver = function () {
    window.resizeObserver.unobserve(window.document.body)
  }

  this.setOverflowY = function (value) {
    if (!window.document.body.style) {
      window.document.body.style = { overflowY: value }
    } else if (window.document.body.style.overflowY !== value) {
      window.document.body.style.overflowY = value
    }
  }
}
