import {
  EM_DECLARATIVE_WIDGET_CLASSNAME,
  EM_DECLARATIVE_WIDGET_STATE_ATTRIBUTE_NAME,
  MUTATION_OBSERVER_DEBOUNCE_MS
} from '../common/Constants'
import { debounce } from 'lodash'
import { foreach } from '../common/Helper'
import { Logger, LEVEL } from '../common/Logger'

const getAllNonRenederedEMWidgets = () => {
  return document.querySelectorAll(
    `div:not([${EM_DECLARATIVE_WIDGET_STATE_ATTRIBUTE_NAME}]).${EM_DECLARATIVE_WIDGET_CLASSNAME}`)
}

/**
 * add Widget
 * @param {object} widgetLauncher
 * @param {object} node - dom node with classname em-widget
 */
const addEMWidget = (emWidgetLauncher, node) => {
  // console.log('top level em widget added', node.dataset)
  const elementDataProperties = node.dataset
  let widgetConfig = {}
  if (!elementDataProperties.name) {
    return
  }

  emWidgetLauncher.init({
    siteId: elementDataProperties.siteId,
    logLevel: elementDataProperties.logLevel || LEVEL.WARN
  })

  try {
    if (elementDataProperties.config) {
      widgetConfig = JSON.parse(elementDataProperties.config)
    }
    emWidgetLauncher.ui({
      hostElement: node,
      name: elementDataProperties.name,
      ariaLabel:elementDataProperties.ariaLabel,
      config: widgetConfig,
      style: {
        width: elementDataProperties.width,
        height: elementDataProperties.height,
        minHeight: elementDataProperties.minHeight || 0
      },
      extends: elementDataProperties.extends || '',
    })
  } catch (e) {
    Logger.error('Unable to parse widget config for ', elementDataProperties.config)
  }
}

/**
 * setup mutation observer when a new element is added or removed
 * It will look for className em-widget to add the widget
 * It a existing em-widget is removed, it will clean up from launcher
 * @param {object} widgetLauncher
 */
const setupMutationObserver = function (emWidgetLauncher) {
  if (!window.MutationObserver) {
    return
  }
  // Select the node that will be observed for mutations
  const targetNode = document.getElementsByTagName('body')[0]

  // Options for the observer (which mutations to observe)
  const config = { childList: true, subtree: true }

  // call back to manage widget instance
  const callback = function (mutationsList) {
    const allEMWidgets = getAllNonRenederedEMWidgets()
    Logger.debug('mutation occurred render widgets ', allEMWidgets)
    foreach(allEMWidgets, (emWidget) => {
      addEMWidget(emWidgetLauncher, emWidget)
    })
    const launcher = emWidgetLauncher.getLauncherInstance()
    if (launcher && launcher.widgetInstances) {
      foreach(Object.keys(launcher.widgetInstances), (wid) => {
        if (!document.querySelector("iframe[data-wid='" + wid + "']")) {
          launcher.destroyWidget(launcher.widgetInstances[wid])
        }
      })
    }
  }
  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(debounce(callback, MUTATION_OBSERVER_DEBOUNCE_MS))

  // Start observing the target node for configured mutations
  observer.observe(targetNode, config)
}

/**
 * setup discover widget in document object modal.
 * It scans DOM and look for classname em-widget
 * @param {object} widgetLauncher
 */
export const launchDeclarativeWidget = function (emWidgetLauncher) {
  let readyStateCheck
  const maxCheckCount = 240 // each check timeout is 250ms, total duration to check for 240 times * 250ms = 60s
  let checkCount = 0
  const setupWhenDocumentIsComplete = function () {
    // to handle the script is placed in body section
    readyStateCheck = setTimeout(() => {
      if (checkCount >= maxCheckCount) {
        clearTimeout(readyStateCheck)
      }
      if (document.readyState === 'complete') {
        clearTimeout(readyStateCheck)

        const allEMWidgets = getAllNonRenederedEMWidgets()
        foreach(allEMWidgets, (emWidget) => {
          addEMWidget(emWidgetLauncher, emWidget)
        })

        // to handle widget being added after later
        setupMutationObserver(emWidgetLauncher)
      } else {
        setupWhenDocumentIsComplete()
      }
      checkCount++
    }, 250)
  }
  setupWhenDocumentIsComplete()
}
