import { EM_EVENT } from './Constants'
/**
 * helper module.
 * @module Helper
 */
/**
 * helper method to parse query string and return query string in key/value pair
 * @param {string} queryString the string after the ? in the url
 * @returns {object} queryStringObject the query string in object format (key/value) pair
 */
export function queryStringToObject (queryString) {
  if (!queryString) {
    return {}
  }
  if (queryString.indexOf('?') === 0) {
    queryString = queryString.substring(1)
  }
  const params = {}
  let temp
  // Split into key/value pairs
  const queries = queryString.split('&')
  // Convert the array of strings into an object
  for (let i = 0, l = queries.length; i < l; i++) {
    temp = queries[i].split('=')
    params[temp[0]] = decodeURIComponent(temp[1] || '')
  }
  return Object.assign({}, params)
}

/**
 * convert query parameters object to query string
 * @param {object} queryParameterObject a key/value pair object
 * @returns {string} queryString query string for url
 */
export function objectToQueryString (queryParameterObject) {
  if (!queryParameterObject) {
    return ''
  }
  const queryStringArray = Object.keys(queryParameterObject).map(key => `${key}=${encodeURIComponent(queryParameterObject[key])}`) //eslint-disable-line
  return queryStringArray.join('&')
}

/**
 * Return url of current web address
 * @returns {string} return url without query parameter
 */
export function getCurrentResourceURL () {
  return encodeURI(`${window.location.origin}${window.location.pathname}`)
}

/**
 * Return widget instance id
 * @param {string} widget numbner
 * @returns {string} return widget instance id
 */
export function resolveWidgetInstanceId (instanceId) {
  return `_WID_${instanceId}_`
}

export function resolveWindowProperties () {
  return {
    location: {
      origin: window.location.origin,
      search: window.location.search,
      hash: window.location.hash,
      href: window.location.href,
      host: window.location.host,
      hostname: window.location.hostname,
      pathname: window.location.pathname,
      port: window.location.port,
      protocol: window.location.protocol
    },
    state: window.history.state,
    top: {
      location: {
        origin: window.top.location.origin,
        search: window.top.location.search,
        hash: window.top.location.hash,
        href: window.top.location.href,
        host: window.top.location.host,
        hostname: window.top.location.hostname,
        pathname: window.top.location.pathname,
        port: window.top.location.port,
        protocol: window.top.location.protocol
      }
    },
    performance: {
      navigation: {
        type: window.performance.navigation.type
      },
      timing: {
        fetchStart: window.performance.timing.fetchStart
      }
    },
    pageXOffset: window.pageXOffset,
    pageYOffset: window.pageYOffset,
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight,
    visualViewport: {
      height: window.visualViewport.height,
      offsetLeft: window.visualViewport.offsetLeft,
      offsetTop: window.visualViewport.offsetTop,
      pageLeft: window.visualViewport.pageLeft,
      pageTop: window.visualViewport.pageTop,
      scale: window.visualViewport.scale,
      width: window.visualViewport.width
    }
  }
}

function calcElementOffset (el) {
  const rect = el.getBoundingClientRect()
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

export function resolveIFrameProperties (iFrameDom) {
  const iFramePropModel = {
    offsetTop: 0,
    offsetLeft: 0
  }
  if (!iFrameDom) {
    return iFramePropModel
  }
  const pos = calcElementOffset(iFrameDom)
  iFramePropModel.offsetTop = pos.top
  iFramePropModel.offsetLeft = pos.left
  return iFramePropModel
}

function getObjectProperty (object, property) {
  if (!object) {
    return
  }
  return object[property]
}

/**
 * get the property value of the object by path
 * @param {object} obj
 * @param {string} path
 */
export function getPropertyByPath (obj, path) {
  const propertyPathArray = path.split('.')
  const currentProperty = propertyPathArray.shift()
  const currentPropertyValue = getObjectProperty(obj, currentProperty)
  if (propertyPathArray.length > 0) {
    return getPropertyByPath(currentPropertyValue, propertyPathArray.join('.'))
  } else {
    return currentPropertyValue
  }
}

export function foreach (array, fn) {
  return Array.prototype.forEach.call(array, fn)
}

/**
 * This will intercept the event object properties depending on type
 * This will fix the issue for event request coming from IDP login page to widget for receiving app data
*/
export function eventFilter (event) {
  if (event.data && event.data.source === 'elli:remoting') {
    // console.warn('==== Filtered message from SSF ====', event.origin, event.data)
    return null
  }
  if ((event.data && event.data.indexOf && event.data.indexOf('[iFrameSizer]') === 0)) {
    // console.warn('==== Filtered message iFrameSizer ====', event.origin, event.data)
    return null
  }
  // normalize event object for EVENT EM_EVENT.appDataRequestFromIdp2Widget
  if (event.data && event.data.type === EM_EVENT.appDataRequestFromIdp2Widget) {
    event.data.name = event.data.type
    return event.data
  }
  // console.log('===== continue to handle received message ====', event)
  try {
    return JSON.parse(event.data)
  } catch (e) {
    console.warn('Unable to parse event.data', event.data)
    return null
  }
}
/**
 * Find the view port dimension
 */
export function getViewPortDimension () {
  return {
    height: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
    width: Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  }
}

/**
 * return object from window namespace
 * The return must be an object, not a property or function
 * @param {*} extendsObjPath
 */
export const resolveObjectFromNamespace = (extendsObjPath) => {
  if (!extendsObjPath) {
    return
  }
  // append window as root namespace if not specified
  extendsObjPath = extendsObjPath.replace(/^window\./, '')

  // start path crawling here
  const obj = getPropertyByPath(window, extendsObjPath)

  // retrun undefined if nothing is found or result is not an object
  if (!(obj && Object.prototype.toString.call(obj) === '[object Object]')) {
    return
  }
  return obj
}

export const resolvePropertiesFromObject = (obj, pName) => {
  if (!(obj[pName])) {
    return
  }
  return obj[pName]
}

// case insensitive filter object properties
export const filterObjectProperties = (originObject, propertyFilterNameList, isInclude) => {
  const resolvedParams = {}
  if (!originObject) {
    return resolvedParams
  }
  const lowercaseOriginPropertyNameMap = {}
  Object.keys(originObject).forEach((key) => {
    lowercaseOriginPropertyNameMap[key.toLowerCase()] = key
  })
  const lowercasePropertyFilterNameList = propertyFilterNameList.map((key) => {
    return key.toLowerCase()
  })
  const lowercaseOriginPropertyNameList = Object.keys(lowercaseOriginPropertyNameMap)
  if (isInclude) {
    lowercaseOriginPropertyNameList.forEach((prop) => {
      const index = lowercasePropertyFilterNameList.indexOf(prop)
      if (index >= 0) {
        resolvedParams[propertyFilterNameList[index]] = originObject[lowercaseOriginPropertyNameMap[prop]]
      }
    })
  } else {
    lowercaseOriginPropertyNameList.forEach((prop) => {
      if (lowercasePropertyFilterNameList.indexOf(prop) < 0) {
        resolvedParams[lowercaseOriginPropertyNameMap[prop]] = originObject[lowercaseOriginPropertyNameMap[prop]]
      }
    })
  }
  return resolvedParams
}

export const migrateMetadata = (metadata) => {
  /**
   * convert loginWindow to externalLoginWindow
   * in LA release, the flag was used as loginWindow
   * in GA release, the flag becomes externalLoginWindow
   * Do the following to keep it backward compatible
   */
  if (Object.prototype.hasOwnProperty.call(metadata, 'loginWindow') &&
  !Object.prototype.hasOwnProperty.call(metadata, 'externalLoginWindow')) {
    metadata.externalLoginWindow = metadata.loginWindow
  }
  if (Object.prototype.hasOwnProperty.call(metadata, 'loginWindow') &&
  Object.prototype.hasOwnProperty.call(metadata, 'externalLoginWindow')) {
    metadata.loginWindow = metadata.externalLoginWindow
  }
  return metadata
}
